import React from 'react'

import PhoneNumberLink from '../../atoms/PhoneNumberLink'
import MonitoringHeader from '../../organisms/MonitoringHeader/BillingHeader'

const MonitoringPage: React.FC = () => {
  return (
    <>
      <div className="container page-content d-flex flex-column flex-grow-1">
        <MonitoringHeader />
        <>
          <div role="alert" className="fade m-0 mt-2 mb-1 alert alert-warning show">
            Внимание!
            <br />
            Этот функционал не доступен, так как у вас не подключена услуга «Мониторинг рейсов».
            <br />
            Для подключения обратитесь к Даниилу,
            <br />
            Варианты связи:
            <br />
            <a href="https://t.me/d2623" target="_blank" rel="noopener noreferrer">
              Телеграм
            </a>
            <br />
            <PhoneNumberLink phone="79955075757" />
          </div>
        </>
      </div>
    </>
  )
}

export default MonitoringPage
