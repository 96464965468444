export class Shipper {
  private static regexps = [
    /Белуга/i,
    /фмло(дж|г|ж)ис(к?тик|ткис)/i,
    /(м(е|э)йд?ж(е|о)р|Major)/i,
    /мега[\s-]*полис/i,
    /М[^\\а-яa-z]*Видео/i,
    /МВМ/i,
    /пэк/i,
    /Спортмастер/i,
  ]

  public static isRiskyShipper(shipper: string): boolean {
    for (let i = 0; i < this.regexps.length; i += 1) {
      const cleanShipper = `${shipper}`.replace(/[^a-zа-я]/gi, '')
      if (cleanShipper.match(this.regexps[i])) return true
    }

    return false
  }
}
