import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { OrderFields, OrderTypeId } from 'types/order'
import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'
import { changeOrderHandler } from 'store/order/order.actions'
import { getDateForServer } from 'utils/date'
import InputControl from 'components/molecules/InputControl'
import InputDate from 'components/molecules/InputDate'
import Title from 'components/atoms/Title'
import { RouteProps } from './index'
import PhoneNumberLink from '../../../../atoms/PhoneNumberLink'

const Customer: React.FC<RouteProps> = ({ onChange }) => {
  const dispatchStore = useDispatch()
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)
  const [showAdditionalFields, setShowAdditionalFields] = useState(false)

  const routeDateStartSetToday = useCallback(() => {
    dispatchStore(
      changeOrderHandler({
        [OrderFields.ROUTE_DATE_START]: getDateForServer(new Date()),
      }),
    )
  }, [dispatchStore])

  const routeDateStartSetTomorrow = useCallback(() => {
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    dispatchStore(
      changeOrderHandler({
        [OrderFields.ROUTE_DATE_START]: getDateForServer(tomorrow),
      }),
    )
  }, [dispatchStore])

  const toggleAdditionalFields = () => {
    setShowAdditionalFields((prevShow) => !prevShow)
  }

  const isAdditionalFieldsVisible =
    [OrderTypeId.DRIVER_AND_TS, OrderTypeId.LOGISTIC_AND_DRIVER, OrderTypeId.DRIVER_FOR_LOADING].includes(
      String(form[OrderFields.ORDER_TYPE]) as OrderTypeId,
    ) && showAdditionalFields

  return (
    <>
      <Title text="Рейс и маршрут" />
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="p-0">
            <InputDate
              id={OrderFields.ROUTE_DATE_START}
              value={form[OrderFields.ROUTE_DATE_START]}
              isValid={errors[OrderFields.ROUTE_DATE_START]?.isValid}
              invalidMessage={errors[OrderFields.ROUTE_DATE_START]?.invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Дата начала:"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
            <Button className="p-0" style={{ flex: '1', marginRight: '4px' }} onClick={routeDateStartSetToday}>
              Сегодня
            </Button>
            <Button className="p-0" style={{ flex: '1', marginLeft: '4px' }} onClick={routeDateStartSetTomorrow}>
              Завтра
            </Button>
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="text"
              id={OrderFields.SHIPPER}
              value={form[OrderFields.SHIPPER]}
              isValid={errors[OrderFields.SHIPPER]?.isValid}
              invalidMessage={errors[OrderFields.SHIPPER]?.invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Грузоотправитель / Заказчик:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="text"
              id={OrderFields.ROUTE_TRIP}
              value={form[OrderFields.ROUTE_TRIP]}
              isValid={errors[OrderFields.ROUTE_TRIP]?.isValid}
              invalidMessage={errors[OrderFields.ROUTE_TRIP]?.invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Маршрут:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              type="text"
              id={OrderFields.CARGO}
              value={form[OrderFields.CARGO]}
              isValid={errors[OrderFields.CARGO]?.isValid}
              invalidMessage={errors[OrderFields.CARGO]?.invalidMessage}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Груз:"
            />
          </div>
        </li>
        {isAdditionalFieldsVisible ? (
          <>
            <li className="list-group-item row">
              <div role="alert" className="fade m-0 mt-2 mb-1 alert alert-warning show ">
                Внимание!
                <br />
                Этот функционал не доступен, так как у вас не подключена услуга «Мониторинг рейсов».
                <br />
                Для подключения обратитесь к Даниилу,
                <br />
                Варианты связи:
                <br />
                <a href="https://t.me/d2623" target="_blank" rel="noopener noreferrer">
                  Телеграм
                </a>
                <br />
                <PhoneNumberLink phone="79955075757" />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField1"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Номер рейса"
                  disabled
                />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField2"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Имя логиста"
                  disabled
                />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField3"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Телефон логиста"
                  disabled
                />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField4"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Адрес точки 1"
                  disabled
                />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField5"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="ПВП на точку 1"
                  disabled
                />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField6"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Адрес точки 2"
                  disabled
                />
              </div>
            </li>
            <li className="list-group-item row">
              <div className="p-0">
                <InputControl
                  type="text"
                  id="additionalField7"
                  value=""
                  invalidMessage=""
                  onChangeHandler={() => {}}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="ПВП на точку 2"
                  disabled
                />
              </div>
            </li>
          </>
        ) : null}
        {String(form[OrderFields.ORDER_TYPE]) !== OrderTypeId.TS ? (
          <li className="list-group-item row">
            <div className="p-0 d-flex justify-content-around">
              <Button onClick={toggleAdditionalFields}>{showAdditionalFields ? 'Скрыть' : 'Отправить в диспетчеризацию после проверки'}</Button>
            </div>
          </li>
        ) : null}
      </ul>
    </>
  )
}

export default Customer
