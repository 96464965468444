export const ordersListPath = '/'
export const orderPath = '/order/:orderNumber'
export const authPath = '/auth'
export const createOrderPath = '/order/create'
export const editOrderPath = '/order/:orderNumber/edit'
export const adminPath = '/admin/:bodyType'
export const searchOrderPath = '/search'
export const flightMonitoring = '/monitoring'
export const statisticPath = '/statistic'
export const billingPath = '/billing'
export const checksPath = '/checks'
export const expertsOnlinePath = '/experts/online'
export const l1OnlinePath = '/l1/online'
export const chatsPath = '/chats'
