import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { HIGH_RISK_CITIES } from 'constants/order'

import { OrderFields, OrderSpaceName } from 'types/order'

import { selectOrderRead } from 'store/order/order.selectors'

import { getDateForWeb } from 'utils/date'

import { Icon, IconType } from 'components/atoms/Icon'

import { onClickAnchor } from 'components/molecules/OrderSpace/spaces/Header/components/utils/block'
import GrayText from 'components/atoms/GrayText'
import BoldText from 'components/atoms/BoldText'
import classes from 'components/molecules/OrderSpace/spaces/Header/style.module.css'
import { Shipper } from 'components/molecules/OrderSpace/spaces/Header/components/Expert/shipper'

const RE_HIGH_RISK_CITIES = new RegExp(`(^|[^А-я])+(${HIGH_RISK_CITIES.join('|')})($|[^А-я])+`, 'ig')

const Route: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const shipperIsRisky = useMemo(() => Shipper.isRiskyShipper(form[OrderFields.SHIPPER]), [form])
  const routeTripIsRisky = useMemo(() => RE_HIGH_RISK_CITIES.test(form[OrderFields.ROUTE_TRIP]), [form])

  return (
    <>
      <div className={classes.space}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span className={classNames(classes.icon, classes.anchor)} onClick={() => onClickAnchor(`__${OrderSpaceName.ROUTE}`)} role="button">
            <Icon type={IconType.Box} width="24px" height="24px" />
          </span>
        </div>
        <div className={classes.spaceBody}>
          <GrayText text="Рейс и Маршрут" />
          <div>
            <BoldText
              text={
                <>
                  {form[OrderFields.ROUTE_DATE_START] === getDateForWeb(new Date()) ? 'Сегодня ' : ''}
                  {form[OrderFields.ROUTE_DATE_START] === getDateForWeb(new Date(new Date().setDate(new Date().getDate() + 1))) ? 'Завтра ' : ''}
                  {form[OrderFields.ROUTE_DATE_START]}
                </>
              }
            />
          </div>
          <div>
            <BoldText text={<span className={routeTripIsRisky ? classes.redBackgroundColor : ''}>{form[OrderFields.ROUTE_TRIP]}</span>} />
          </div>
          <div>
            <BoldText text={<span className={shipperIsRisky ? classes.redBackgroundColor : ''}>{form[OrderFields.SHIPPER]}</span>} />
          </div>
          <div>
            <BoldText text={form[OrderFields.CARGO]} />
          </div>
          {!form[OrderFields.ROUTE_DATE_START] && !form[OrderFields.ROUTE_TRIP] && !form[OrderFields.SHIPPER] && !form[OrderFields.CARGO] ? (
            <p className="p-0 m-0">Не предоставлены данные по рейсу</p>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default Route
